<div class="w-full flex items-center flex-col">
  <h1>Login</h1>

  <button
    type="button"
    class="btn-main disabled:opacity-30 transition-transform hover:scale-95 disabled:hover:transform-none"
    (click)="showPatron()">
    Show patron
  </button>
  @if (name$ | async; as name) {
    <p>Hello {{ name }}</p>
  }
  @if (patron) {
    <h1>
      {{ patron }}
    </h1>
  }
  @if ((isAuthenticated$ | async) === false) {
    <div>
      <button
        (click)="signOut()"
        class="btn-main disabled:opacity-30 transition-transform hover:scale-95 disabled:hover:transform-none">
        Sign out
      </button>
    </div>
  }
  <marquee direction="left">Login page bb</marquee>
</div>
