<div class="h-32 italic text-gray-600 overflow-y-auto">
  @for (item of legalTextData.text; track item) {
    <p>
      @for (part of parseText(item); track part) {
        @if (!isLink(part)) {
          {{ part }}
        } @else {
          <a [href]="part?.href" [target]="part?.target">{{ part?.text }}</a>
        }
      }
    </p>
    <br />
  }
</div>
