import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import * as appPackage from '../../../../../package.json';
import { ConfigurationService } from '../../services/configuration.service';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  imports: [CommonModule],
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  switchColor: boolean;
  subscriptions: Subscription[] = [];
  appPackage = appPackage;
  configurationService = inject(ConfigurationService);
  private router = inject(Router);

  constructor() {}

  ngOnInit(): void {
    const subscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.switchColor = event.url.includes('seat-selection');
      }
    });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
