import { Component, Input } from '@angular/core';
import { LegalTextData, LegalTextLink } from '@core/models';

@Component({
  standalone: true,
  selector: 'app-legal-terms',
  templateUrl: './legal-terms.component.html',
  styleUrls: ['./legal-terms.component.css'],
})
export class LegalTermsComponent {
  @Input() legalTextData!: LegalTextData; // Updated to LegalTextData type
  private textWithBracketsRegex = /(\{.+?\})/;
  private textWithinBracketsRegex = /\{(.+?)\}/;

  /**
   * Parse the text, replacing placeholders with links.
   * @param text The text string to parse
   * @returns An array of strings and LegalTextLink objects
   */
  protected parseText(text: string): (string | LegalTextLink)[] {
    const textParts: (string | LegalTextLink)[] = [];
    const splitText = text.split(this.textWithBracketsRegex); // Split by the text block by placeholder, which is text surrounded by brackets: ej {legalLink}

    for (const part of splitText) {
      const textWithinBrackets = part.match(this.textWithinBracketsRegex)?.[1];

      if (
        textWithinBrackets &&
        this.legalTextData.links?.[textWithinBrackets]
      ) {
        textParts.push(this.legalTextData.links[textWithinBrackets]); // Replace with the corresponding link object in legalTextData
      } else {
        textParts.push(part); // Push plain text
      }
    }

    return textParts;
  }

  protected isLink(part: string | LegalTextLink): part is LegalTextLink {
    return typeof part !== 'string';
  }
}
