import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { TransactionWrapper } from '@core/models';
import { catchError, map, of } from 'rxjs';
import { ConnectionService } from '../services/connection.service';

export const checkoutResolver: ResolveFn<TransactionWrapper | null> = route => {
  const configurationService = inject(ConnectionService);

  configurationService.token = route.queryParams['token'];
  const transactionId = route.params['id'];
  return configurationService.getTransactionById(transactionId).pipe(
    map(transaction => transaction.data ?? null),
    catchError(() => {
      return of(null);
    })
  );
};
