import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked {
  private cdRef = inject(ChangeDetectorRef);
  title = 'chicago-cubs-online-group-sales';

  constructor() {}

  ngOnInit() {
    if (environment.analytics && environment.analytics.mmc) {
      this.loadGtagScripts(environment.analytics.mmc);
    }
    if (environment.analytics && environment.analytics.club.length > 0) {
      environment.analytics.club.forEach(tag => {
        this.loadGTMScripts(tag);
      });
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  loadGtagScripts(analyticsId: string) {
    // first part analytics node
    const node = document.createElement('script'); // creates the script tag
    node.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`; // sets the source (insert url in between quotes)
    node.async = true; // makes script run asynchronously
    node.type = 'text/javascript'; // set the script type
    document.getElementsByTagName('head')[0].appendChild(node);
    // second part analytics node
    const node2 = document.createElement('script'); // creates the script tag
    node2.innerHTML = `window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', '${analyticsId}');`;
    node2.type = 'text/javascript'; // set the script type
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node2);
  }

  loadGTMScripts(analyticsId: string) {
    const nodeHeadGTM = document.createElement('script'); // creates the script tag
    nodeHeadGTM.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${analyticsId}');
    `;
    document.getElementsByTagName('head')[0].appendChild(nodeHeadGTM);
    const nodeBodyGTM = document.createElement('noscript'); // creates the script tag
    nodeBodyGTM.innerHTML = `
    <iframe src=https://www.googletagmanager.com/ns.html?id=${analyticsId}
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.getElementsByTagName('body')[0].appendChild(nodeBodyGTM);
  }
}
