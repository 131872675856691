import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, Event, NavigationEnd, Router } from '@angular/router';
import { ModalsService } from '@core/modals/modals.service';
import { DvmService, TutorialService } from '@core/services';
import { IsolatedSeatsService } from '@features/digital-venue/services/isolated-seats.service';
import { ConnectionService } from '@features/pages/select-seat/services/connection.service';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { ConnectionService as CheckoutConnectionService } from '../../../features/pages/checkout/services/connection.service';
import { SaleEvent } from '../../models';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  imports: [CommonModule, NgOptimizedImage],
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  // Booleans
  landing: boolean;
  showLegend: boolean;
  showTutorialButton = false;

  // Strings
  transactionId: string;
  actualPage: string;
  backBtn: 'payment' | 'summary' | undefined;

  // Colors
  availableColor: string;
  mySeatsColor: string;
  selectedColor: string;
  isolatedColor: string;

  // Event
  event: SaleEvent;

  // Subscriptions
  private subscriptions: Subscription[] = [];

  // Constants
  readonly DATE_TIME = DateTime.local();

  router = inject(Router);
  isolatedSeatsService = inject(IsolatedSeatsService);
  configurationService = inject(ConfigurationService);
  private connectionService = inject(ConnectionService);
  private configurationConnectionService = inject(CheckoutConnectionService);
  private dvmService = inject(DvmService);
  private modalsService = inject(ModalsService);
  private tutorialService = inject(TutorialService);

  // Getters
  get isTopview() {
    return this.dvmService.isTopView();
  }
  get isSectionMap() {
    return !this.dvmService.isTopView();
  }
  get is3dOpen() {
    return this.dvmService.is3dView();
  }
  get isIsolatedActive() {
    return this.isolatedSeatsService.isolatedSeatsActive;
  }
  get timeZone() {
    return this.DATE_TIME.offsetNameShort;
  }

  constructor() {
    this.mySeatsColor = 'red';
    this.selectedColor = 'purple';
    this.isolatedColor = 'orange';
  }

  ngOnInit(): void {
    this.initComponent();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private initComponent() {
    const routerEventSubscription = this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationEnd) {
          this.showLegend = event.url.includes('seat-selection');
          this.showTutorialButton =
            event.url.includes('seat-selection') ||
            event.url.includes('checkout');
          this.landing = event.url.includes('events');
        }

        if (event instanceof ActivationEnd && event.snapshot.url.length) {
          this.transactionId = event.snapshot.params['id'];

          this.actualPage = event.snapshot.url[0].path;
          switch (this.actualPage) {
            case 'payment':
              this.backBtn = 'payment';
              break;
            case 'summary':
              this.backBtn = 'summary';
              break;
            default:
              break;
          }
        }
      }
    );

    const getEventSubscription = this.connectionService
      .getEvent()
      .subscribe(data => (this.event = data));

    this.subscriptions.push(routerEventSubscription, getEventSubscription);
  }

  goBack(page: string) {
    let backPage = 'checkout';
    if (page === 'summary') {
      backPage = 'payment';
    }

    this.router.navigate([`${backPage}/${this.transactionId}`], {
      queryParams: { token: this.configurationConnectionService.token },
    });
  }

  goBackToTopview() {
    if (this.dvmService.is3dView()) {
      // Close 3D
      this.dvmService.close3dView();
    } else {
      const backSub = this.dvmService.openSectionMap().subscribe();
      this.subscriptions.push(backSub);
    }
  }

  openLegend() {
    const dynamicLegend = this.isTopview
      ? { text: 'Selected', color: this.selectedColor }
      : { text: 'Isolated', color: this.isolatedColor };

    const modalData = {
      title: 'Location Legend',
      content: `<span id="legend">
                  <span class="mb-4 flex justify-center">
                    <span class="mx-2 flex items-center">
                      <span class="w-2 h-2 mx-2 bg-dvm-available rounded-full"></span>
                      <p>Available</p>
                    </span>
                    <span class="mx-2 flex items-center">
                      <span class="w-2 h-2 mx-2 bg-${this.mySeatsColor} rounded-full"></span>
                      <p>My seats</p>
                    </span>
                    <span class="mx-2 flex items-center">
                      <span class="w-2 h-2 mx-2 bg-${dynamicLegend.color} rounded-full"></span>
                      <p>${dynamicLegend.text}</p>
                    </span>
                  </span>
                </span>`,
      acceptBtnName: 'CLOSE',
    };
    this.modalsService.openModal(modalData);
  }

  // Tutorial
  openTutorialQuestion() {
    this.tutorialService.openTutorial();
  }

  isNotFoundRoute() {
    return this.router.url === '/not-found';
  }
}
