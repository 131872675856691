import { inject } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { ConnectionService } from '../services/connection.service';

export const eventLandingResolver = () => {
  return inject(ConnectionService)
    .getEventsList(1, { month: null, day: null, opponent: null })
    .pipe(
      map(list => list),
      catchError(error => {
        console.log(error);
        return of(false);
      })
    );
};
