import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {
  setDefaultEnvironment,
  setDefaultModuleVersion,
} from '@3ddv/dvm-internal';
import { DvmModule, MapViewerService } from '@3ddv/ngx-dvm-internal';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TitleStrategy } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSliderModule } from 'ngx-slider-v2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { NumbersInputDirective } from './core/directives/numbers-input.directive';
import { EnvironmentInterceptor } from './core/interceptors/environment.interceptor';
import { FooterComponent } from './core/layout/footer/footer.component';
import { HeaderComponent } from './core/layout/header/header.component';
import { LayoutComponent } from './core/layout/layout.component';
import { PaginationModule } from './core/layout/pagination/pagination.module';
import { LoginComponent } from './core/login/login.component';
import { ForbiddenEventModalComponent } from './core/modals/forbidden-event-modal/forbidden-event-modal.component';
import { GaModalComponent } from './core/modals/ga-modal/ga-modal.component';
import { ModalsComponent } from './core/modals/modals.component';
import { RestrictedSeatsModalComponent } from './core/modals/restricted-seats-modal/restricted-seats-modal.component';
import { SeatDefinitionsModalComponent } from './core/modals/seat-definitions-modal/seat-definitions-modal.component';
import { TutorialWelcomeModalComponent } from './core/modals/tutorial-welcome-modal/tutorial-welcome-modal.component';
import { OktaIntegrationModule } from './core/okta-integration/okta-integration.module';
import { ConfigurationService } from './core/services/configuration.service';
import { TemplatePageTitleStrategyService } from './core/services/template-page-title-strategy.service';
import { SeatPopoverComponent } from './features/digital-venue/popovers/seat-popover/seat-popover.component';
import { SectionPopoverComponent } from './features/digital-venue/popovers/section-popover/section-popover.component';
import { CheckoutComponent } from './features/pages/checkout/checkout.component';
import { LegalTermsComponent } from './payment/legal-terms/legal-terms.component';

setDefaultModuleVersion('map_viewer', 'stable');
setDefaultModuleVersion('3d_viewer', 'stable');
setDefaultEnvironment('core_pro');

/**
 * Initializes application with certain parameters fetched from the URL and backend.
 * It's intended to run before the application fully bootstraps.
 * @param http - The HTTP client used for making the request.
 * @returns A function that returns a promise, signaling the app initialization completion.
 */
function initializeApp(configurationService: ConfigurationService): () => void {
  return () => configurationService.init();
}

const appInitObject = {
  provide: APP_INITIALIZER,
  useFactory: initializeApp,
  deps: [ConfigurationService],
  multi: true,
};

const environmentInterceptorObject = {
  provide: HTTP_INTERCEPTORS,
  useClass: EnvironmentInterceptor,
  multi: true,
};

const titleStrategy = {
  provide: TitleStrategy,
  useClass: TemplatePageTitleStrategyService,
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ModalsComponent,
    SeatDefinitionsModalComponent,
    GaModalComponent,
    RestrictedSeatsModalComponent,
    SectionPopoverComponent,
    SeatPopoverComponent,
    NotFoundComponent,
    NumbersInputDirective,
    TutorialWelcomeModalComponent,
    ForbiddenEventModalComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    LayoutComponent,
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    CheckoutComponent,
    DvmModule,
    FooterComponent,
    FormsModule,
    HeaderComponent,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    ModalModule.forRoot(),
    NgxSliderModule,
    OktaIntegrationModule,
    PaginationModule,
  ],
  providers: [
    titleStrategy,
    appInitObject,
    environmentInterceptorObject,
    MapViewerService,
    provideHttpClient(withInterceptorsFromDi()),
    LegalTermsComponent,
  ],
})
export class AppModule {}
