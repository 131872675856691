import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError, mergeMap, of } from 'rxjs';
import { SaleEvent } from '@core/models';
import { ConnectionService } from '../services/connection.service';
import { PrivateSellingService } from '../services/private-selling.service';

@Injectable({
  providedIn: 'root',
})
export class EventDataResolver {
  private connectionService = inject(ConnectionService);
  private privateSellingService = inject(PrivateSellingService);

  public resolve(route: ActivatedRouteSnapshot) {
    const ev = route.queryParamMap.get('event')!;
    const eventId = parseInt(ev, 10);
    this.connectionService.eventId = eventId;

    const isBackFromMlbWebsite = this.isBackFromMlbWebsite(route);

    if (isBackFromMlbWebsite) {
      this.privateSellingService.handleStateBackingFromMlb();

      return of({ event: null, error: 'Event is not on sale.' });
    }

    const validEventId = this.getEventId(route);
    this.connectionService.eventId = validEventId as number;

    if (!validEventId) {
      return of({ event: null, error: 'Invalid event requested.' });
    }

    return this.connectionService.getEventById(validEventId).pipe(
      mergeMap(response => this.handleEvent(response.data as SaleEvent)),
      catchError(error => of({ event: null, error }))
    );
  }

  /**
   * Extracts and validates the event ID from the route query parameters.
   * @param route - The active route snapshot.
   * @returns The extracted event ID, or null if invalid.
   */
  private getEventId(route: ActivatedRouteSnapshot): number | null {
    const eventParam = route.queryParamMap.get(
      this.privateSellingService.QUERY_PARAMS.EVENT
    );
    const eventId = parseInt(eventParam ?? '', 10);
    return isNaN(eventId) ? null : eventId;
  }

  /**
   * Handles event-specific logic, including private selling authentication.
   * @param event - The event data to handle.
   * @returns A promise that resolves to the processed event data.
   */
  private async handleEvent(event: SaleEvent) {
    this.privateSellingService.isEnabled.set(
      Boolean(event?.isPrivateSellingEnabled)
    );

    const isPrivateSellingEnabled = this.privateSellingService.isEnabled();
    const hasNotAttemptedLogin = !this.privateSellingService.isLoginAttempted();

    if (isPrivateSellingEnabled && hasNotAttemptedLogin) {
      await this.privateSellingService.authenticate();
    }

    return event;
  }

  /**
   * Determines if the user navigated back to the page from an external site (MLB website).
   *
   * This function exists due to the ability to press back from the MLB login page and landing
   * on the `seat-selection` page. This action would make the webpage to behave in a weird way, so
   * it was decided to determine this scenario (with this function) and take action from there to
   * control the situation with a warning modal or anything of the like.
   *
   * @returns True if navigated back from a website from another domain, otherwise false.
   */
  private isBackFromMlbWebsite(route: ActivatedRouteSnapshot) {
    const perfEntries = performance?.getEntriesByType('navigation');
    let entry: PerformanceEntry & { type: string } = {} as PerformanceEntry & {
      type: string;
    };
    if (perfEntries.length) {
      entry = perfEntries[0] as PerformanceEntry & { type: string };
    }

    // This param is attached by MLB whenever we arrive to its webpage, then it is kept
    const isParamPresent = route.queryParamMap.has(
      this.privateSellingService.QUERY_PARAMS.IS_SIGN_IN_COMPLETE
    );

    // https://stackoverflow.com/questions/25806608/how-to-detect-browser-back-button-event-cross-browser
    // This is the type of event that we get whenever we come from another
    // site from having clicked the back button in the explorer. If we click the back button
    // in this same domain, we get the event `navigate` instead.
    const backForwardEvent = 'back_forward';
    return (
      entry?.type === backForwardEvent &&
      isParamPresent &&
      window.history.length > 2
    );
  }
}
