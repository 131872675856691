{
  "name": "group-sales-portal",
  "version": "2.0.0",
  "scripts": {
    "build-dev": "ng build --configuration development --aot --output-hashing=all",
    "build-pre": "ng build --configuration preproduction --aot --output-hashing=all",
    "build-pro": "ng build --configuration production --aot --output-hashing=all",
    "build": "ng build",
    "cy:dev:run": "cypress run -C cypress/config/cypress.dev.js",
    "cy:dev": "cypress open -C cypress/config/cypress.dev.js --e2e",
    "cy:local:run": "cypress run -C cypress/config/cypress.local.js",
    "cy:local": "cypress open -C cypress/config/cypress.local.js --e2e",
    "cy:pre:run": "cypress run -C cypress/config/cypress.pre.js",
    "cy:pre": "cypress open -C cypress/config/cypress.pre.js --e2e",
    "cy:prod:run": "cypress run -C cypress/config/cypress.prod.js",
    "cy:prod": "cypress open -C cypress/config/cypress.prod.js --e2e",
    "lint": "ng lint",
    "ng": "ng",
    "start:dev": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration development",
    "start:local:chicagocubs:ssl": "ng serve --host local.chicagocubs.3ddigitalvenue.com --open --port 4200 --configuration local --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key'",
    "start:local:chicagocubs": "ng serve --host local.chicagocubs.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:chicagocubsspring": "ng serve --host local.chicagocubsspring.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:chicagocubsspring:ssl": "ng serve --host local.chicagocubsspring.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration local",
    "start:local:miamimarlins": "ng serve --host local.miamimarlins.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:miamimarlins:ssl": "ng serve --host local.miamimarlins.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration local",
    "start:local:pittsburghpirates": "ng serve --host local.pittsburghpirates.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:pittsburghpirates:ssl": "ng serve --host local.pittsburghpirates.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration local",
    "start:local:redsox": "ng serve --host local.redsox.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:redsox:ssl": "ng serve --host local.redsox.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration local",
    "start:local:washingtonnationals": "ng serve --host local.washingtonnationals.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:washingtonnationals:ssl": "ng serve --host local.washingtonnationals.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration local",
    "start:local:houstonastros": "ng serve --host local.houstonastros.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:houstonastros:ssl": "ng serve --host local.houstonastros.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration local",
    "start:dev:chicagocubs": "ng serve --host local.chicagocubs.3ddigitalvenue.com --open --port 4200 --configuration development",
    "start:dev:chicagocubsspring": "ng serve --host local.chicagocubsspring.3ddigitalvenue.com --open --port 4200 --configuration development",
    "start:dev:houstonastros": "ng serve --host local.houstonastros.3ddigitalvenue.com --open --port 4200 --configuration development",
    "start:dev:miamimarlins": "ng serve --host local.miamimarlins.3ddigitalvenue.com --open --port 4200 --configuration development",
    "start:dev:pittsburghpirates": "ng serve --host local.pittsburghpirates.3ddigitalvenue.com --open --port 4200 --configuration development",
    "start:dev:redsox": "ng serve --host local.redsox.3ddigitalvenue.com --open --port 4200 --configuration development",
    "start:dev:washingtonnationals": "ng serve --host local.washingtonnationals.3ddigitalvenue.com --open --port 4200 --configuration development",
    "start:pre:astros": "ng serve --host local.houstonastros.3ddigitalvenue.com --port 4200 --open --configuration preproduction",
    "start:pre:chicagocubs": "ng serve --host local.chicagocubs.3ddigitalvenue.com --open --port 4200 --configuration preproduction",
    "start:pre:chicagocubs:ssl": "ng serve --host local.chicagocubs.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration preproduction",
    "start:pre:chicagocubsspring": "ng serve --host local.chicagocubsspring.3ddigitalvenue.com --open --port 4200 --configuration preproduction",
    "start:pre:houstonastros": "ng serve --host local.houstonastros.3ddigitalvenue.com --open --port 4200 --configuration preproduction",
    "start:pre:miamimarlins": "ng serve --host local.miamimarlins.3ddigitalvenue.com --open --port 4200 --configuration preproduction",
    "start:pre:pittsburghpirates": "ng serve --host local.pittsburghpirates.3ddigitalvenue.com --open --port 4200 --configuration preproduction",
    "start:pre:redsox": "ng serve --host local.redsox.3ddigitalvenue.com --port 4200 --open --configuration preproduction",
    "start:pre:ssl": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration preproduction",
    "start:pre:washingtonnationals": "ng serve --host local.washingtonnationals.3ddigitalvenue.com --open --port 4200 --configuration preproduction",
    "start:pre": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration preproduction",
    "start:prod": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration production",
    "start:ssl": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' ",
    "test": "ng test",
    "watch": "ng build --watch --configuration development"
  },
  "private": true,
  "dependencies": {
    "@3ddv/dvm-internal": "^2.6.1",
    "@3ddv/ngx-dvm-internal": "^4.0.0",
    "@angular/animations": "^18.2.13",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@ngx-loading-bar/core": "^6.0.2",
    "@ngx-loading-bar/http-client": "^6.0.2",
    "@okta/okta-angular": "^6.3.2",
    "@okta/okta-auth-js": "^7.5.0",
    "@popperjs/core": "^2.11.8",
    "@types/animejs": "^3.1.12",
    "animejs": "^3.2.2",
    "cypress-plugin-snapshots": "^1.4.4",
    "driver.js": "^1.3.1",
    "luxon": "^3.5.0",
    "ngx-bootstrap": "~12.0.0",
    "ngx-device-detector": "~7.0.0",
    "ngx-slider-v2": "~17.0.0",
    "npn-slider": "^1.3.2",
    "rxjs": "^7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-eslint/builder": "18.0.0-alpha.16",
    "@angular-eslint/eslint-plugin": "18.0.0-alpha.16",
    "@angular-eslint/eslint-plugin-template": "18.0.0-alpha.16",
    "@angular-eslint/schematics": "18.0.0-alpha.16",
    "@angular-eslint/template-parser": "18.0.0-alpha.16",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.13",
    "@testing-library/cypress": "^10.0.2",
    "@types/braintree-web-drop-in": "^1.39.3",
    "@types/jasmine": "~5.1.4",
    "@types/luxon": "^3.4.2",
    "@types/node": "^20.11.5",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "angular-http-server": "^1.12.0",
    "autoprefixer": "^10.4.17",
    "cypress": "^13.15.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "~8.4.18",
    "prettier": "^3.2.5",
    "prettier-eslint": "^16.3.0",
    "rimraf": "~5.0.5",
    "tailwindcss": "^3.3.3",
    "typescript": "~5.5.4",
    "zod": "^3.22.4"
  }
}
