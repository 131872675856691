import { Component, inject } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { HeaderComponent } from './header/header.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FooterComponent } from './footer/footer.component';

@Component({
  imports: [AppRoutingModule, FooterComponent, HeaderComponent],
  selector: 'app-layout',
  standalone: true,
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  private loadingBar = inject(LoadingBarService);
  constructor() {
    this.loadingBar.useRef();
  }
}
