<div
  id="header"
  class="h-full w-full flex items-center justify-between text-white px-2 py-1 md:px-3"
  [ngClass]="[showLegend ? 'bg-black' : 'bg-main-dark']">
  <!-- LOGO & CLUB NAME -->
  <div class="mr-6 items-center hidden md:flex">
    @if (!isNotFoundRoute()) {
      <ng-container>
        <img
          [ngSrc]="configurationService.configuration.logo"
          alt="Club logo"
          class="h-12"
          width="48"
          height="48"
          [priority]="false" />
        <div class="h-10 flex items-center">
          <h3 class="min-w-max ml-3">
            {{ configurationService.configuration.clientName }}
          </h3>
          @if (!landing && event) {
            <ng-container>
              <div class="h-full mx-5 border-l border-current"></div>
              <h5
                [title]="event.description"
                class="text-nowrap text-ellipsis overflow-hidden max-w-56">
                <span class="mr-1 font-normal">Event:</span>
                {{ event.description }}
              </h5>
              <div class="h-full mx-5 border-l border-current"></div>
              <h5
                [title]="event.date | date: 'MMM d, y, h:mm a'"
                class="text-nowrap text-ellipsis overflow-hidden max-w-40">
                <span class="mr-1 font-normal"></span>
                {{ event.date | date: 'MMM d, y, h:mm a' }}
                <span class="text-xs"
                  >(All Times {{ this.timeZone }}. Subject to change.)</span
                >
              </h5>
            </ng-container>
          }
        </div>
      </ng-container>
    }
  </div>
  <!-- Mobile -->
  <div class="mr-6 flex items-center md:hidden">
    @if (backBtn === 'payment' || backBtn === 'summary') {
      <div>
        <button
          class="btn-icon bg-transparent disabled:opacity-30 transition-transform hover:scale-95 disabled:hover:transform-none"
          (click)="goBack(backBtn)">
          <img
            ngSrc="/assets/img/arrow-left-white-mobile-icon.svg"
            alt="Arrow left white icon"
            width="10"
            height="10" />
        </button>
      </div>
    } @else if (isSectionMap || is3dOpen) {
      <div>
        <button
          class="btn-icon bg-transparent disabled:opacity-30 transition-transform hover:scale-95 disabled:hover:transform-none"
          (click)="goBackToTopview()">
          <img
            ngSrc="/assets/img/arrow-left-white-mobile-icon.svg"
            alt="Arrow left white icon"
            width="10"
            height="10" />
        </button>
      </div>
    }
    @if (!isNotFoundRoute()) {
      <ng-container>
        <img
          [ngSrc]="configurationService.configuration.logo"
          [priority]="true"
          alt="Club logo"
          width="32"
          height="32" />
        <h5 class="min-w-max ml-2">
          {{ configurationService.configuration.clientName }}
        </h5>
      </ng-container>
    }
  </div>

  <!-- SEAT-SELECTION -->
  @if (showLegend && !is3dOpen) {
    <div class="ml-6 items-center hidden lg:flex">
      <div id="legend" class="flex text-nowrap text-ellipsis overflow-hidden">
        <P class="mr-3">Legend:</P>
        <div class="mr-3 flex items-center">
          <div class="w-2 h-2 mx-2 bg-dvm-available rounded-full"></div>
          <p>Available</p>
        </div>
        <div class="mr-3 flex items-center">
          <div class="w-2 h-2 mx-2 bg-{{ mySeatsColor }} rounded-full"></div>
          <p>My seats</p>
        </div>
        @if (isTopview) {
          <div class="mr-3 flex items-center">
            <div class="w-2 h-2 mx-2 bg-{{ selectedColor }} rounded-full"></div>
            <p>Selected</p>
          </div>
        }
        @if (!isTopview && isIsolatedActive) {
          <div class="mr-3 flex items-center">
            <div class="w-2 h-2 mx-2 bg-{{ isolatedColor }} rounded-full"></div>
            <p>Isolated</p>
          </div>
        }
      </div>
      <div class="h-8 mx-3 border-l border-gray-600"></div>
      <button
        class="disabled:opacity-30 transition-transform hover:scale-95 disabled:hover:transform-none"
        (click)="openTutorialQuestion()">
        <img
          ngSrc="/assets/img/information-icon.svg"
          alt="Information icon"
          class="mx-3"
          width="25"
          height="25" />
      </button>
    </div>
  }
  <!-- Mobile -->
  @if (showLegend && !is3dOpen) {
    <div class="flex lg:hidden items-center">
      <button
        (click)="openLegend()"
        class="disabled:opacity-30 transition-transform hover:scale-95 disabled:hover:transform-none btn-white-reverse w-min h-8 align-middle">
        Legend
      </button>
      <button (click)="openTutorialQuestion()">
        <img
          ngSrc="/assets/img/information-icon.svg"
          alt="Information icon"
          class="disabled:opacity-30 transition-transform hover:scale-95 disabled:hover:transform-none ml-2"
          width="25"
          height="25" />
      </button>
    </div>
  }
</div>
