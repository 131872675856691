import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { eventLandingGuard } from '@features/pages/event-landing/event-landing.guard';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { RedirectPageComponent } from './core/okta-integration/components/redirect-page/redirect-page.component';
import { checkoutResolver } from './features/pages/checkout/resolvers/checkout.resolver';
import { eventLandingResolver } from './features/pages/event-landing/resolvers/event-landing.resolver';
import { opponentsResolver } from './features/pages/event-landing/resolvers/opponents.resolver';
import { paymentResolver } from './features/pages/payment/resolvers/payment.resolver';
import { EventDataResolver } from './features/pages/select-seat/resolvers/event-data.resolver';
import { summaryResolver } from './features/pages/summary/resolvers/summary.resolver';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'seat-selection',
  },
  {
    path: 'okta/redirect',
    component: RedirectPageComponent,
  },
  {
    path: '',
    children: [
      {
        path: 'events',
        loadChildren: () =>
          import('./features/pages/event-landing/event-landing.module').then(
            m => m.EventLandingModule
          ),
        resolve: {
          eventList: eventLandingResolver,
          opponentList: opponentsResolver,
        },
        canActivate: [eventLandingGuard],
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'seat-selection',
        loadChildren: () =>
          import('./features/pages/select-seat/select-seat.module').then(
            m => m.SelectSeatModule
          ),
        resolve: {
          event: EventDataResolver,
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'login',
        component: OktaCallbackComponent,
      },
      {
        path: 'checkout/:id', // <---- Transaction id
        loadChildren: () =>
          import('./features/pages/checkout/checkout.module').then(
            m => m.CheckoutModule
          ),
        resolve: {
          transaction: checkoutResolver,
        },
      },
      {
        path: 'payment/:id', // <---- Transaction id
        loadChildren: () =>
          import('./features/pages/payment/payment.module').then(
            m => m.PaymentModule
          ),
        resolve: {
          transaction: paymentResolver,
        },
      },
      {
        path: 'summary/:id', // <---- Transaction id
        loadChildren: () =>
          import('./features/pages/summary/summary.module').then(
            m => m.SummaryModule
          ),
        resolve: {
          transaction: summaryResolver,
        },
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: '**', // Unknown route protection
    redirectTo: 'seat-selection',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
