import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { TransactionWrapper } from '@core/models';
import { ConnectionService } from '../services/connection.service';

export const paymentResolver: ResolveFn<TransactionWrapper | null> = route => {
  const configurationService = inject(ConnectionService);
  const transactionId = route.params['id'];
  configurationService.token = route.queryParams['token'];
  return configurationService.getTransactionById(transactionId).pipe(
    map(transaction => transaction.data ?? null),
    catchError(() => {
      return of(null);
    })
  );
};
